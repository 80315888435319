
































import { formatDate } from "@/lib/date";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { AppealHistoryFileInfo } from "@/models/appeals";
import { mapGetters } from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import DocGetter from "@/components/mixins/DocGetter.vue";

@Component({
  computed: {
    ...mapGetters({
      contractId: "contract/id",
      currentAppealInfo: "appeals/getCurrentAppealInfo",
    })
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "fullWithTime");
    }
  }
})
class AppealChatMessage extends Mixins(AppApiMixin, DocGetter) {
  [x: string]: any;

  @Prop({ default: [] }) readonly item!: [];

  fileInfo: any;

  public get messageClass(): String {
    return 'pa-2 pl-4 pr-4 ' + (this.item['состояние'] ? 'chat-message-stack' : 'chat-message')
  }

  downloadAppealHistoryFile(fileId, text_info) {
    if (text_info.match(/^(Прикреплен\sфайл)/)) {
      this.getHistoryAppealFileInfo({
        contractId: this.contractId,
        appealId: this.currentAppealInfo[0]['appealId'],
        fileId: fileId
      },(appealFileClickInfo: AppealHistoryFileInfo[]) => {
        this.fileInfo = appealFileClickInfo;
        this.downloadTheFile();
      });
    }
  }

  public downloadTheFile(): void {
    const {
      appealHistoryFileDownload,
      downloadDocument
    } = this;
    appealHistoryFileDownload({
      fileUrl: this.fileInfo['fileUrl'],
      fileId: this.fileInfo['fileId'],
      download: true,
    }).then((response) => downloadDocument(response))
        .catch(console.error);
  }
}
export default AppealChatMessage;
